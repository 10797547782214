
// It is being used to calculate the column count to display Associated Diseases and Associated Microbes on disease and microbe page
export function getColumnCount(length: number): number {
  if (length <= 10) {
    return 1;
  } else if (length > 10 && length <= 50) {
    return 2;
  } else {
    return 3;
  }
}

export function getWidth(length: number): string {
  if (length < 10) {
    return '60%';
  } else if (length > 10 && length <= 50) {
    return '100%';
  } else {
    return '100%';
  }
}
import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'ExcludedDiseases/LoadInitiated'>
  | ActionWithPayload<'ExcludedDiseases/Loaded', ExcludedDisease[]>
  | ActionWithPayload<'ExcludedDiseases/LoadFailed', StoreError>;

export interface ExcludedDisease {
  id: number;
  name: string;
  slug: string;
}

export interface ExcludedDiseases {
  data: {
    [id: number]: ExcludedDisease;
  };
  list: number[];
}

export type State = Resource<ExcludedDiseases> | null;

const initialState: State = null;

export const {
  dispatchContext: ExcludedDiseasesDispatchContext,
  stateContext: ExcludedDiseasesStateContext,
  provider: ExcludedDiseasesStoreProvider,
} = storeFactory(reducer, initialState);

import { Loader } from 'Atoms/Loader';
import { H1, Span } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { isEqual } from 'lodash';
import { ConfirmationModal } from 'Molecules/modal/ConfirmationModal';
import { AccountForm } from 'Organisms/AccountForm';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Prompt } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import { useState as useAccountState } from 'store/accountStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { AccountSettings } from 'types/accountSettings';

const MainLayoutStyled = styled(MainLayout)`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 30px;
  }
`;

const StyledTitle = styled(H1)``;

const Subtitle = styled(Span)`
  margin: 0 0 60px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0;
  }
`;

export const Account: FC = () => {
  const [inputState, setInputState] = useState<AccountSettings>();
  const { accountSettings } = useAccountState();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [leavePath, setLeavePath] = useState<string>('');
  const { push } = usePushState();

  assertIsNotStoreError(accountSettings);

  useEffect(() => {
    if (!isLoading(accountSettings)) {
      setInputState({ ...accountSettings });
    }
  }, [accountSettings]);

  const handleBlockedNavigation = (path: string): boolean => {
    const stateChanged = !isEqual(inputState, accountSettings);

    if (stateChanged && !confirmationModal) {
      setConfirmationModal(true);
      setLeavePath(path);
      return false;
    }
    return true;
  };

  if (!inputState) {
    return <Loader />;
  }

  const onInputChange = (value: string, type: string): void =>
    setInputState({
      ...inputState,
      [type]: value,
    });

  if (inputState.accountType === 'institution_user') {
    return (
      <MainLayoutStyled>
        <Helmet>
          <title>Account - GIDEON</title>
        </Helmet>
        <StyledTitle>
          Access to GIDEON provided courtesy of{' '}
          {inputState.institution_name || inputState.institution || 'your institution'}
        </StyledTitle>
      </MainLayoutStyled>
    );
  }

  const getTitle = (): string => {
    return inputState.accountType === 'institution_admin'
      ? `${inputState.firstName} ${inputState.lastName}`
      : inputState.institution
      ? inputState.institution
      : 'Account information';
  };

  const getSubTitle = (): string | null => {
    if (
      inputState.accountType === 'institution_admin' &&
      (inputState.email === 'MBucco@epnet.com' ||
        inputState.email === 'HealthLRP-ProductStrategy@wolterskluwer.com')
    ) {
      return 'Partner account management';
    } else if (inputState.accountType === 'institution_admin') {
      return 'Account management';
    }

    return null;
  };

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Account - GIDEON</title>
      </Helmet>
      <Prompt message={location => handleBlockedNavigation(location.pathname)} />
      <ConfirmationModal
        isOpen={confirmationModal}
        description="You have unsaved changes. Do you wish to proceed?"
        onClose={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          push(leavePath);
        }}
        cancelLabel="No"
        confirmLabel="Yes"
      />
      <StyledTitle>{getTitle()}</StyledTitle>
      <Subtitle size="small">{getSubTitle()}</Subtitle>
      <AccountForm
        inputState={inputState}
        onInputChange={onInputChange}
        setInputState={newState => setInputState(newState)}
      />
    </MainLayoutStyled>
  );
};

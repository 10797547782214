import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';
import { DiseaseCountry } from 'types/disease';
import { groupDataAlphabetically } from 'utils/getGroupedData';

const Wrapper = styled.div`
  column-count: 5;
  column-width: 300px;
  column-gap: 20px;
  padding: 20px 0;
`;

const Group = styled.div`
  break-inside: avoid-column;
  display: table;
  margin: 20px 0 0 0;
  &:first-child {
    margin-top: 0;
  }
`;

const GroupItem = styled.div`
  display: block;
  margin: 5px 0;
`;

const OutbreakLabel = styled(P)`
  margin-top: 5px;
`;

interface Props {
  countries: DiseaseCountry[];
  latestOutbreaks?: LatestOutbreak[];
  diseaseId: string;
}

export const DiseaseCountrySection: FC<Props> = ({ countries, diseaseId, latestOutbreaks }) => {
  const groupedList = groupDataAlphabetically(countries, true);
  return (
    <Wrapper>
      {Object.keys(groupedList)
        .sort()
        .map((key: string) => (
          <Group key={key}>
            <P weight="700" size="normal">
              {key}
            </P>
            {groupedList[key].map(item => {
              if (item.hasCountryNote) {
                return (
                  <GroupItem key={item.id}>
                    <Link to={`/explore/diseases/${diseaseId}/${item.id}`}>{item.name}</Link>
                  </GroupItem>
                );
              } else if (!item.hasCountryNote) {
                return (
                  <GroupItem key={item.id}>
                    <P>{item.name}</P>
                  </GroupItem>
                );
              }
            })}
          </Group>
        ))}
    </Wrapper>
  );
};

import { ReactComponent as GraphSVG } from 'assets/Button/Graph.svg';
import { ReactComponent as DiseaseSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as MapSvg } from 'assets/UI/Map.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { DynamicRecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle, TitleContainer } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { VaccinationSection } from 'Molecules/section/VaccinationSection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { NotFound } from 'pages/404/';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { usePushState } from 'services/usePushState.hook';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { useDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

import { Schedule } from './DiseaseInCountry/DiseaseInCountry';

const largeNotes = [11170];

const StyledRecordTitle = styled(RecordTitle)`
  ${TitleContainer} {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 15px;
  }
`;

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }
  padding: 0;
`;

const StatsButton = styled(StyledButton)`
  margin-left: 10px;
`;

const DiseaseButton = styled(StyledButton)`
  margin-left: 10px;
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

type Props = RouteComponentProps & {
  diseaseId: number;
};

export const WorldwideNotes: FC<Props> = ({ match, diseaseId }) => {
  const [showBackground, setShowBackground] = useState(true);
  const [showGraph, setShowGraph] = useState(true);
  const [showTransmission, setShowTransmission] = useState(true);
  const [showHumanIncidence, setShowHumanIncidence] = useState(true);
  const [showAnimalIncidence, setShowAnimalIncidence] = useState(true);
  const [showVaccinations, setShowVaccinations] = useState(true);
  const [showPrevalenceSurveys, setShowPrevalenceSurveys] = useState(true);
  const [showSeroprevalenceSurveys, setShowSeroprevalenceSurveys] = useState(true);
  const [showCrossBorder, setShowCrossBorder] = useState(true);
  const [showOutbreaks, setShowOutbreaks] = useState(true);
  const [showCaseSeries, setShowCaseSeries] = useState(true);
  const [showSporadic, setShowSporadic] = useState(true);
  const [showRelatedDisease, setShowRelatedDisease] = useState(true);
  const [showRelatedCountries, setShowRelatedCountries] = useState(true);
  const [showReviewAndUpdates, setShowReviewAndUpdates] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);
  const { push } = usePushState();

  const disease = useDiseaseDetails(diseaseId);

  const worldwideNotes = useCountryNotes(diseaseId, 'G100');
  if (isLoading(disease) || isLoading(worldwideNotes)) {
    return (
      <Loader
        message={
          largeNotes.includes(diseaseId)
            ? 'This is a large note, it may take a moment to load.'
            : undefined
        }
      />
    );
  }

  assertIsNotStoreError(disease);
  assertIsNotStoreError(worldwideNotes);

  if (!disease.countries.isWorldwideAvailable) {
    return <NotFound />;
  }

  const path = match.url.split('/');

  const description = `GIDEON - ${
    disease.details.disease
  } - Worldwide note. Worldwide distribution: ${getStringFromDynamicHtml(worldwideNotes.note)}`;

  const title = `${disease.details.disease} worldwide distribution`;

  const sections: DynamicRecordLink[] = [
    {
      label: 'Background',
      'aria-label': title,
      intro: worldwideNotes.allSection[`background_intro`],
      accent: 'first',
      disabled: worldwideNotes.allSection.background ? false : true,
    },
    {
      label: `Graphs (${worldwideNotes.allSection.graphs?.length})`,
      'aria-label': `${disease} graphs`,
      accent: 'first',
      disabled: worldwideNotes.allSection.graphs?.length ? false : true,
    },
    {
      label: worldwideNotes.allSection.vaccination_graphs?.length
        ? `Vaccinations (${worldwideNotes.allSection.vaccination_graphs.length} graphs)`
        : 'Vaccinations',
      'aria-label': `${disease} vaccinations`,
      accent: 'first',
      disabled:
        worldwideNotes.allSection.vaccinations_intro ||
        worldwideNotes.allSection.vaccinations ||
        worldwideNotes.allSection.vaccination_graphs
          ? false
          : true,
    },
    {
      label: 'Transmission',
      'aria-label': `${disease} transmission`,
      accent: 'second',
      disabled: worldwideNotes.allSection.transmission?.length ? false : true,
    },
    {
      label: 'Incidence in humans',
      'aria-label': `${disease} incidence in human`,
      accent: 'second',
      disabled: worldwideNotes.allSection.incidence_in_humans ? false : true,
    },
    {
      label: 'Incidence in animals',
      'aria-label': `${disease} incidence in animals`,
      accent: 'second',
      disabled: worldwideNotes.allSection.incidence_in_animals ? false : true,
    },
    {
      label: `Prevalence surveys (${worldwideNotes.prevalence.length})`,
      'aria-label': `${disease} prevalence surveys`,
      accent: 'second',
      disabled: worldwideNotes.prevalence.length > 0 ? false : true,
    },
    {
      label: `Seroprevalence surveys (${worldwideNotes.seroprevalence.length})`,
      'aria-label': `${disease} prevalence surveys`,
      accent: 'third',
      disabled: worldwideNotes.seroprevalence.length > 0 ? false : true,
    },
    {
      label: `Cross-border events (${worldwideNotes.crossborders?.length})`,
      'aria-label': `${disease} cross-border events`,
      intro: worldwideNotes.allSection[`crossborder_intro`],
      accent: 'third',
      disabled: worldwideNotes.allSection.crossborder?.length ? false : true,
    },
    {
      label: `Sporadic events (${worldwideNotes.allSection.sporadic?.length})`,
      'aria-label': `${disease} Sporadic events`,
      intro: worldwideNotes.allSection[`sporadic_intro`],
      accent: 'third',
      disabled: worldwideNotes.allSection.sporadic?.length ? false : true,
    },
    {
      label: `Outbreaks (${worldwideNotes.outbreaks?.length})`,
      'aria-label': `${disease} outbreaks`,
      intro: worldwideNotes.allSection[`outbreaks_intro`],
      accent: 'third',
      disabled: worldwideNotes.allSection.outbreaks?.length ? false : true,
    },
    {
      label: `Case series (${worldwideNotes.caseSeries?.length})`,
      'aria-label': `${disease} case series`,
      accent: 'fourth',
      disabled: worldwideNotes.caseSeries?.length ? false : true,
    },
    {
      label: `Related Diseases (${worldwideNotes.relatedDiseases?.length})`,
      'aria-label': `${disease} Related diseases`,
      accent: 'fourth',
      disabled: worldwideNotes.relatedDiseases?.length ? false : true,
    },
    {
      label: `Related Countries (${worldwideNotes.relatedCountries?.length})`,
      'aria-label': `${disease} Related countries`,
      accent: 'fourth',
      disabled: worldwideNotes.relatedCountries?.length ? false : true,
    },
    {
      label: 'Reviews and updates',
      'aria-label': `${disease} Reviews and updates`,
      intro: worldwideNotes.allSection[`reviews_and_updates_intro`],
      accent: 'fourth',
      disabled: worldwideNotes.allSection.reviews_and_updates?.length ? false : true,
    },
    {
      label: 'Downloads and references',
      accent: 'fifth',
      'aria-label': `${disease} worldwide downloads and references`,
    },
  ];

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{title} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={title}>
          <RecordLayout>
            <StyledRecordTitle
              title={title}
              buttons={
                <Buttons>
                  <StyledButton
                    onClick={() => {
                      push(
                        path
                          .filter((_, i) => i < path.length - 1)
                          .concat(['outbreaks'])
                          .join('/')
                      );
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={MapSvg} />
                    Map
                  </StyledButton>
                  <DiseaseButton
                    onClick={() => {
                      push(path.filter((_, i) => i < path.length - 1).join('/'));
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={DiseaseSvg} />
                    Disease
                  </DiseaseButton>
                  <StatsButton
                    padding="none"
                    size="micro"
                    onClick={() => push(`/visualize/graphs?disease=${diseaseId}&country=G100`)}
                  >
                    <StyledIcon svgComponent={GraphSVG} />
                    Stats
                  </StatsButton>
                </Buttons>
              }
              showTimeStamp={getTimeStamp(worldwideNotes.timestamp)}
            />
            <RecordLinks links={sections} />
            {/* Background */}
            {worldwideNotes.allSection.background && (
              <RecordSection
                link={sections[0]}
                show={showBackground}
                onCollapse={() => setShowBackground(!showBackground)}
                accent={sections[0].accent}
                id="Background"
              >
                {sections[0].intro && <DynamicHtml content={sections[0].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.background} />
              </RecordSection>
            )}
            {/* Graphs */}
            {worldwideNotes.allSection.graphs && (
              <RecordSection
                link={sections[1]}
                show={showGraph}
                onCollapse={() => setShowGraph(!showGraph)}
                accent={sections[1].accent}
                id="Graphs"
              >
                {worldwideNotes.allSection.graphs.map((graph, index) => (
                  <DynamicHtml content={graph} key={index} />
                ))}
              </RecordSection>
            )}
            {/* Vaccinations */}
            <RecordSection
              link={sections[2]}
              show={showVaccinations}
              onCollapse={() => setShowVaccinations(!showVaccinations)}
              accent={sections[2].accent}
              id="Vaccinations"
            >
              {worldwideNotes.allSection.vaccinations && (
                <>
                  <Schedule>Schedule</Schedule>{' '}
                  <VaccinationSection data={[worldwideNotes.allSection.vaccinations]} />
                </>
              )}

              {worldwideNotes.allSection.vaccinations_intro && (
                <DynamicHtml content={worldwideNotes.allSection.vaccinations_intro} />
              )}
              {worldwideNotes.allSection.vaccination_graphs &&
                worldwideNotes.allSection.vaccination_graphs.map((graph, index) => (
                  <DynamicHtml content={graph} key={index} />
                ))}
            </RecordSection>
            {/* Transmission */}
            {worldwideNotes.allSection.transmission && (
              <RecordSection
                link={sections[3]}
                show={showTransmission}
                onCollapse={() => setShowTransmission(!showTransmission)}
                accent={sections[3].accent}
                id="Transmission"
              >
                <DynamicHtml content={worldwideNotes.allSection.transmission} />
              </RecordSection>
            )}
            {/* Incidence in humans */}
            {worldwideNotes.allSection.incidence_in_humans && (
              <RecordSection
                link={sections[4]}
                show={showHumanIncidence}
                onCollapse={() => setShowHumanIncidence(!showHumanIncidence)}
                accent={sections[4].accent}
                id="Human Incidence"
              >
                <DynamicHtml content={worldwideNotes.allSection.incidence_in_humans} />
              </RecordSection>
            )}
            {/* Incidence in animals */}
            {worldwideNotes.allSection.incidence_in_animals && (
              <RecordSection
                link={sections[5]}
                show={showAnimalIncidence}
                onCollapse={() => setShowAnimalIncidence(!showAnimalIncidence)}
                accent={sections[5].accent}
                id="Animal Incidence"
              >
                <DynamicHtml content={worldwideNotes.allSection.incidence_in_animals} />
              </RecordSection>
            )}
            {/* Prevalence Surveys */}
            {worldwideNotes.allSection.prevalence && (
              <RecordSection
                link={sections[6]}
                show={showPrevalenceSurveys}
                onCollapse={() => setShowPrevalenceSurveys(!showPrevalenceSurveys)}
                accent={sections[6].accent}
                id="Prevalence"
              >
                {worldwideNotes.allSection.prevalence.map((survey, index) => (
                  <DynamicHtml content={survey} key={index} />
                ))}
              </RecordSection>
            )}
            {/* Seroprevalence Surveys */}
            {worldwideNotes.allSection.seroprevalence && (
              <RecordSection
                link={sections[7]}
                show={showSeroprevalenceSurveys}
                onCollapse={() => setShowSeroprevalenceSurveys(!showSeroprevalenceSurveys)}
                accent={sections[7].accent}
                id="Seroprevalence"
              >
                {worldwideNotes.allSection.seroprevalence.map((survey, index) => (
                  <DynamicHtml content={survey} key={index} />
                ))}
              </RecordSection>
            )}
            {/* Crossborder */}
            {worldwideNotes.allSection.crossborder && (
              <RecordSection
                link={sections[8]}
                show={showCrossBorder}
                onCollapse={() => setShowCrossBorder(!showCrossBorder)}
                accent={sections[8].accent}
                id="Crossborder"
              >
                {sections[8].intro && <DynamicHtml content={sections[8].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.crossborder} />
              </RecordSection>
            )}
            {/* Sporadic */}
            {worldwideNotes.allSection.sporadic && (
              <RecordSection
                link={sections[9]}
                show={showSporadic}
                onCollapse={() => setShowSporadic(!showSporadic)}
                accent={sections[9].accent}
                id="Sporadic"
              >
                {sections[9].intro && <DynamicHtml content={sections[9].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.sporadic} />
              </RecordSection>
            )}
            {/* Outbreaks */}
            {worldwideNotes.allSection.outbreaks && (
              <RecordSection
                link={sections[10]}
                show={showOutbreaks}
                onCollapse={() => setShowOutbreaks(!showOutbreaks)}
                accent={sections[10].accent}
                id="Outbreaks"
              >
                {sections[10].intro && <DynamicHtml content={sections[10].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.outbreaks} />
              </RecordSection>
            )}
            {/* Case series */}
            {worldwideNotes.allSection.case_series && (
              <RecordSection
                link={sections[11]}
                show={showCaseSeries}
                onCollapse={() => setShowCaseSeries(!showCaseSeries)}
                accent={sections[11].accent}
                id="Outbreaks"
              >
                {sections[11].intro && <DynamicHtml content={sections[11].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.case_series} />
              </RecordSection>
            )}
            {/*Related Disease*/}
            {worldwideNotes.relatedDiseases && (
              <RecordSection
                link={sections[12]}
                show={showRelatedDisease}
                onCollapse={() => setShowRelatedDisease(!showRelatedDisease)}
                accent={sections[12].accent}
                id="Reviews"
              >
                {worldwideNotes.relatedDiseases.length > 0 && (
                  <ul>
                    {worldwideNotes.relatedDiseases.map(d => (
                      <li key={d.id}>
                        <Link to={`/explore/diseases/${d.id}/G100`}>{d.name} in Worldwide</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </RecordSection>
            )}
            {/*Related Countries*/}
            {worldwideNotes.relatedCountries && (
              <RecordSection
                link={sections[13]}
                show={showRelatedCountries}
                onCollapse={() => setShowRelatedCountries(!showRelatedCountries)}
                accent={sections[13].accent}
                id="Reviews"
              >
                {worldwideNotes.relatedCountries.length > 0 && (
                  <ul>
                    {worldwideNotes.relatedCountries.map(d => (
                      <li key={d.id}>
                        <Link to={`/explore/diseases/${disease.details.code}/${d.id}`}>
                          {disease.details.disease} in {d.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </RecordSection>
            )}
            {/* Reviews and updates */}
            {worldwideNotes.allSection.reviews_and_updates && (
              <RecordSection
                link={sections[14]}
                show={showReviewAndUpdates}
                onCollapse={() => setShowReviewAndUpdates(!showReviewAndUpdates)}
                accent={sections[14].accent}
                id="Reviews"
              >
                {sections[14].intro && <DynamicHtml content={sections[14].intro} />}
                <DynamicHtml content={worldwideNotes.allSection.reviews_and_updates} />
              </RecordSection>
            )}
            <RecordSection
              link={sections[sections.length - 1]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent={sections[sections.length - 1].accent}
              id="disease-country-download"
            >
              <DownloadsSection id={`${diseaseId}/G100`} category="diseases" labelTitle={title} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};

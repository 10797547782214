import { Link } from 'Atoms/links/Link';
import { Span } from 'Atoms/text';
import React, { FC, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'services/useMeasure.hook';
import styled from 'styled-components/macro';
import { Content as ContentType, Section } from 'Updates/types/content';
import { formatNumber } from 'utils/formatNumber';

const padding = 20;

const SectionContainer = styled(animated.div)`
  grid-column: 1 / -1;

  border: solid ${props => props.theme.colors.updatesActiveSection.border};
  background: ${props => props.theme.colors.updatesActiveSection.background};

  border-radius: 4px;
  padding: 0 ${padding}px;

  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 10px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin: 0 0 0 40px;
  }
`;

const StatisticItemContainer = styled(Span)`
  margin-top: 15px;
`;

const NumberStyled = styled(animated.span)`
  font-weight: 600;
`;

const getEbooksFormat = (number: number): string => (number > 1 ? 'eBooks' : 'eBook');

interface NumberProps {
  number: number;
}

const Number: FC<NumberProps> = ({ number }) => <NumberStyled>{formatNumber(number)}</NumberStyled>;

interface ActiveSectionsBaseProps {
  data?: ReactNode[][];
  label: ReactNode;
}

const ActiveSectionBase: FC<ActiveSectionsBaseProps> = ({ data, label }) => (
  <>
    <Span weight="600" size="big">
      {label}
    </Span>
    {data && (
      <Content>
        {data.map((column, index) => (
          <Column key={index}>
            {column.map((field, index) => (
              <StatisticItemContainer key={`field-${index}`}>{field}</StatisticItemContainer>
            ))}
          </Column>
        ))}
      </Content>
    )}
  </>
);

const zeroValues = {
  height: 0,
  borderWidth: 0,
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
};

interface Props {
  section: Section;
  open: boolean;
  contentsData: Record<string, ContentType>;
}

export const ActiveSection: FC<Props> = ({ section, open, contentsData }) => {
  const [ref, bounds] = useMeasure<HTMLDivElement>();

  const values = open
    ? {
        height: bounds.height + 2 * padding,
        borderWidth: 1,
        marginTop: 10,
        paddingTop: padding,
        paddingBottom: padding,
      }
    : zeroValues;

  const props = useSpring(values);

  const getCount = (key: string): number => {
    const count = contentsData[key]?.count;

    return count ? parseInt(count.replace(/,/g, '')) : 0;
  };

  const componentData: Record<string, ActiveSectionsBaseProps> = {
    diseases: {
      data: [
        [
          <>
            <Number number={getCount('syndisease_count')} /> related terms
          </>,
          <>
            <Number number={getCount('symptom_count')} /> signs and symptoms
          </>,
          <>
            <Number number={getCount('image_count')} /> images
          </>,
        ],
        [
          <>
            <Number number={getCount('agent_count')} /> agents
          </>,
          <>
            <Number number={getCount('reservoir_count')} /> reservoirs
          </>,
          <>
            <Number number={getCount('vector_count')} /> vectors
          </>,
        ],
        [
          <>
            <Number number={getCount('vehicle_count')} /> vehicles
          </>,
          <>
            <Link to="/az/diseases">Go to A-Z Diseases</Link>
          </>,
        ],
      ],
      label: 'GIDEON infectious diseases database includes...',
    },
    drugs: {
      data: [
        [
          <>
            <Number number={getCount('syndrug_count')} /> trade names
          </>,
          <>
            <Number number={getCount('paratox_count')} /> forms of toxicity
          </>,
          <>
            <Number number={getCount('parinter_count')} /> interacting drugs
          </>,
        ],
        [
          <>
            <Number number={getCount('parasit_count')} /> drug spectra by pathogen
          </>,
          <>
            <Number number={getCount('effect_count')} /> individual drug-specific toxicities
          </>,
          <>
            <Number number={getCount('dinter_count')} /> individual drug-drug interactions
          </>,
        ],
        [
          <>
            <Number number={getCount('drug_reference_count')} /> references
          </>,
          <>
            <Link to="/az/drugs">Go to A-Z Drugs</Link>
          </>,
        ],
      ],
      label: 'GIDEON anti-infective drugs database includes...',
    },
    bacteria: {
      data: [
        [
          <>
            <Number number={getCount('synbacteria_count')} /> related terms
          </>,
          <>
            <Number number={getCount('bacteria_image_count')} /> images
          </>,
          <>
            <Number number={getCount('bacteria_reference_count')} /> references
          </>,
        ],
        [
          <>
            <Number number={getCount('phenobacteria_count')} /> phenotypic tests
          </>,
          <>
            <Number number={getCount('bacteria_ebook_count')} />{' '}
            {getEbooksFormat(getCount('bacteria_ebook_count'))}
          </>,
          <>
            <Link to="/az/microbes/bacteria">Go to A-Z Bacteria</Link>
          </>,
        ],
      ],
      label: 'GIDEON bacteria database includes...',
    },
    eBooks: {
      label: 'GIDEON eBook series include…',
      data: [
        [
          <>
            <Number number={getCount('country_ebook_count')} /> country series{' '}
            {getEbooksFormat(getCount('country_ebook_count'))}
          </>,
          <>
            <Number number={getCount('disease_ebook_count')} /> disease global status series{' '}
            {getEbooksFormat(getCount('disease_ebook_count'))}
          </>,
          <>
            <Number number={getCount('drug_ebook_count')} />{' '}
            {getEbooksFormat(getCount('drug_ebook_count'))} on antimicrobial agents (drugs)
          </>,
        ],
        [
          <>
            <Number number={getCount('vaccine_ebook_count')} />{' '}
            {getEbooksFormat(getCount('vaccine_ebook_count'))} on vaccines and globulin preparations
          </>,
          <>
            <Number number={getCount('outbreak_ebook_count')} />{' '}
            {getEbooksFormat(getCount('outbreak_ebook_count'))} on outbreaks
          </>,
          <>
            <Number number={getCount('survey_ebook_count')} />{' '}
            {getEbooksFormat(getCount('survey_ebook_count'))} on prevalence and seroprevalence
            surveys
          </>,
        ],
        [
          <>
            <Number number={getCount('travel_ebook_count')} />{' '}
            {getEbooksFormat(getCount('travel_ebook_count'))} on cross-border infections
          </>,
          <>
            <Number number={getCount('yeast_ebook_count')} />{' '}
            {getEbooksFormat(getCount('yeast_ebook_count'))} on medically important yeasts
          </>,
          <>
            <Number number={getCount('bacteria_ebook_count')} />{' '}
            {getEbooksFormat(getCount('bacteria_ebook_count'))} on medically important bacteria
          </>,
        ],
      ],
    },
    graphs: {
      data: [
        [
          <>
            <Number number={getCount('graphpoint_count')} /> data points
          </>,
          <>
            <Number number={getCount('country_count')} /> countries and territories
          </>,
          <>
            <Number number={getCount('disease_count')} /> infectious diseases
          </>,
        ],
      ],
      label: 'GIDEON graphs cover…',
    },
    outbreaks: {
      data: [
        [
          <>
            <Number number={getCount('outbreak_count')} /> outbreaks, going back to 1348 AD
          </>,
          <>
            <Number number={getCount('outbreak_reference_count')} /> outbreak references
          </>,
          <>
            <Number number={getCount('travel_disease_count')} /> cross-border infection events with{' '}
            <Number number={getCount('travel_reference_count')} /> references
          </>,
          <>
            <Number number={getCount('sporadic_event_count')} /> sporadic events with{' '}
            <Number number={getCount('sporadic_event_reference_count')} /> references
          </>,
        ],
        [
          <>
            <Number number={getCount('outbreak_ebook_count')} />{' '}
            {getEbooksFormat(getCount('outbreak_ebook_count'))} on outbreaks
          </>,
          <>
            <Number number={getCount('travel_ebook_count')} />{' '}
            {getEbooksFormat(getCount('travel_ebook_count'))} cross-border infection events
          </>,
        ],
      ],
      label: 'GIDEON records on outbreaks include…',
    },
    countries: {
      data: [
        [
          <>
            <Number number={getCount('country_count')} /> countries and territories
          </>,
          <>
            <Number number={getCount('syncountry_count')} /> historical names related to current
            country names
          </>,
          <>
            <Number number={getCount('countrynote_count')} /> country-specific notes, totaling{' '}
            <Number number={getCount('word_count')} /> words
          </>,
        ],
        [
          <>
            <Number number={getCount('country_ebook_count')} />{' '}
            {getEbooksFormat(getCount('country_ebook_count'))} on infectious diseases in every
            location
          </>,
          <>
            <Link to="/az/countries">Go to A-Z Countries</Link>
          </>,
        ],
      ],
      label: 'GIDEON data on countries includes…',
    },
    'country notes': {
      data: [
        [
          <>
            <Number number={getCount('country_count')} /> countries and territories
          </>,
          <>
            The total word count is <Number number={getCount('word_count')} />
          </>,
        ],
      ],
      label: 'GIDEON country-specific notes cover…',
    },
    'case series': {
      data: [
        [
          <>
            <Number number={getCount('case_series_count')} /> case series
          </>,
          <>
            <Number number={getCount('case_series_reference_count')} /> references
          </>,
        ],
      ],
      label: 'GIDEON case series database includes…',
    },
    images: {
      data: [
        [
          <>
            <Number number={getCount('image_count')} /> images of infectious diseases
          </>,
          <>
            <Number number={getCount('bacteria_image_count')} /> images of bacteria
          </>,
          <>
            <Number number={getCount('mycobacteria_image_count')} /> images of mycobacteria
          </>,
        ],
        [
          <>
            <Number number={getCount('viruses_image_count')} /> images of viruses
          </>,
          <>
            <Number number={getCount('yeasts_image_count')} /> images of yeasts
          </>,
        ],
      ],
      label: 'GIDEON images database includes…',
    },
    surveys: {
      data: [
        [
          <>
            <Number number={getCount('survey_count')} /> prevalence and seroprevalence surveys
          </>,
          <>
            <Number number={getCount('survey_reference_count')} /> references
          </>,
          <>
            <Number number={getCount('survey_ebook_count')} />{' '}
            {getEbooksFormat(getCount('survey_ebook_count'))}
          </>,
        ],
      ],
      label: 'GIDEON database includes…',
    },
    references: {
      data: [
        [
          <>
            <Number number={getCount('survey_reference_count')} /> survey references
          </>,
          <>
            <Number number={getCount('outbreak_reference_count')} /> outbreak references
          </>,
          <>
            <Number number={getCount('travel_reference_count')} /> references on cross-border
            infection events
          </>,
          <>
            <Number number={getCount('case_series_reference_count')} /> case series references
          </>,
        ],
        [
          <>
            <Number number={getCount('drug_reference_count')} /> anti-infective drug references
          </>,
          <>
            <Number number={getCount('vaccine_reference_count')} /> vaccine and globulin references
          </>,
          <>
            <Number number={getCount('disease_reference_count')} /> infectious diseases references
          </>,
          <>
            <Number number={getCount('sporadic_event_reference_count')} /> sporadic events
            references
          </>,
        ],
        [
          <>
            <Number number={getCount('bacteria_reference_count')} /> bacteria references
          </>,
          <>
            <Number number={getCount('mycobacteria_reference_count')} /> mycobacteria references
          </>,
          <>
            <Number number={getCount('virus_reference_count')} /> viruses references
          </>,
          <>
            <Number number={getCount('yeasts_reference_count')} /> yeasts references
          </>,
        ],
      ],
      label: 'GIDEON references include…',
    },
    vaccines: {
      data: [
        [
          <>
            <Number number={getCount('synvaccine_count')} /> trade names
          </>,
          <>
            <Number number={getCount('vacctox_count')} /> forms of toxicity
          </>,
          <>
            <Number number={getCount('vaccind_count')} /> forms of contraindication
          </>,
        ],
        [
          <>
            <Number number={getCount('vaccine_reference_count')} /> references
          </>,
          <>
            <Number number={getCount('vaccine_ebook_count')} />{' '}
            {getEbooksFormat(getCount('vaccine_ebook_count'))}
          </>,
          <>
            <Link to="/az/vaccines">Go to A-Z Vaccines</Link>
          </>,
        ],
      ],
      label: 'GIDEON data on vaccines and globulin preparations includes…',
    },
    'trade names': {
      data: [
        [
          <>
            <Number number={getCount('syndrug_count')} /> drug trade names
          </>,
          <>
            <Number number={getCount('synvaccine_count')} /> vaccine and globulin preparation trade
            names
          </>,
        ],
      ],
      label: 'GIDEON lists…',
    },
    mycobacteria: {
      data: [
        [
          <>
            <Number number={getCount('synmycobacteria_count')} /> related terms
          </>,
          <>
            <Number number={getCount('mycobacteria_image_count')} /> images
          </>,
          <>
            <Number number={getCount('phenomycobacteria_count')} /> phenotypic tests
          </>,
        ],
        [
          <>
            <Number number={getCount('mycobacteria_reference_count')} /> references
          </>,
          <>
            <Link to="/az/microbes/mycobacteria">Go to A-Z Mycobacteria</Link>
          </>,
        ],
      ],
      label: 'GIDEON mycobacteria database includes…',
    },
    viruses: {
      data: [
        [
          <>
            <Number number={getCount('synvirus_count')} /> related terms
          </>,
          <>
            <Number number={getCount('viruses_image_count')} /> images
          </>,
          <>
            <Number number={getCount('virus_reference_count')} /> references
          </>,
        ],
      ],
      label: 'GIDEON viruses database includes…',
    },
    'yeasts + algae': {
      data: [
        [
          <>
            <Number number={getCount('synyeast_count')} /> related terms
          </>,
          <>
            <Number number={getCount('yeasts_image_count')} /> images
          </>,
          <>
            <Number number={getCount('phenoyeast_count')} /> phenotypic tests
          </>,
        ],
        [
          <>
            <Number number={getCount('yeasts_reference_count')} /> references
          </>,
          <>
            <Number number={getCount('yeast_ebook_count')} />{' '}
            {getEbooksFormat(getCount('yeast_ebook_count'))}
          </>,
          <>
            <Link to="/az/microbes/yeasts">Go to A-Z Yeasts</Link>
          </>,
        ],
      ],
      label: 'GIDEON yeasts and algae database includes…',
    },
    'lab tests': {
      data: [
        [
          <>
            <Number number={getCount('phenobacteria_count')} /> phenotypic tests to identify
            bacteria
          </>,
          <>
            <Number number={getCount('phenomycobacteria_count')} /> phenotypic tests to identify
            mycobacteria
          </>,
          <>
            <Number number={getCount('phenoyeast_count')} /> phenotypic tests to identify yeasts and
            algae
          </>,
        ],
      ],
      label: 'GIDEON phenotypic tests include…',
    },
    symptoms: {
      label: (
        <>
          GIDEON list <Number number={getCount('symptom_count')} /> symptoms and signs to help
          identify <Number number={getCount('disease_count')} /> diseases
        </>
      ),
    },
  };

  const data = componentData[section.name];

  return data ? (
    <SectionContainer
      style={{
        ...props,
        overflow: props.height.to(x => (x !== bounds.height ? 'hidden' : 'visible')),
      }}
    >
      <div ref={ref}>
        <ActiveSectionBase data={data.data} label={data.label} />
      </div>
    </SectionContainer>
  ) : null;
};

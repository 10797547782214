import { ReactComponent as BioterrorismIcon } from 'assets/Button/Bioterrorism.svg';
import { Button } from 'Atoms/buttons/Button';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { DiseaseCell } from 'Diagnose/types/diagnosisTable';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { ReactElement } from 'react';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

export const Item = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  padding-right: 5px;
  margin-bottom: 5px;
  // display: flex;
  // align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CompareButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 0 0 auto;
`;

const StyledBioterrorismButton = styled(Button)`
  display: inline-block;
  width: unset;
  max-width: unset;
  padding: 6px 6px 2px;
  ${Icon} {
    width: 16px;
    height: 16px;
  }

  margin-left: 10px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1px 4px;
    ${Icon} {
      width: 11px;
      height: 11px;
    }
  }
`;

interface Props {
  className?: string;
  onBioterrorismClick: (code: number) => void;
  cell: DiseaseCell;
}

export const DiagnosisTableDiseaseCell = ({
  className,
  onBioterrorismClick,
  cell,
}: Props): ReactElement => {
  const [props, popper, setElement] = useTooltip('Bioterror note');

  return (
    <Item className={className}>
      <StyledLink to={`/explore/diseases/${cell.value}`}>{cell.label}</StyledLink>
      {cell.hasBioterrorism && (
        <div {...props.events}>
          <StyledBioterrorismButton
            onClick={() => onBioterrorismClick(cell.value)}
            aria-label="Bioterrorism"
            ref={setElement}
            {...props.aria}
          >
            <Icon svgComponent={BioterrorismIcon} />
          </StyledBioterrorismButton>
          {popper}
        </div>
      )}
      {cell.hasCompare && (
        <CompareButtonWrapper>
          <SmartCompareButton section="diseases" id={cell.value} showTooltip />
        </CompareButtonWrapper>
      )}
    </Item>
  );
};

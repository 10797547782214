import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { ImagesSection } from 'Molecules/ImagesSection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { ClinicalFindingsSection } from 'Molecules/section/ClinicalFindingsSection';
import { DiseaseCountrySection } from 'Molecules/section/DiseaseCountrySection';
import { DiseaseSummarySection } from 'Molecules/section/DiseaseSummarySection';
import { DistributionSection } from 'Molecules/section/DistributionSection';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { TransmissionSection } from 'Molecules/section/TransmissionSection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useDiseaseDetails } from 'store/diseaseDetailStore/hooks';
import { isLoading } from 'store/types';
import { isDisabled } from 'types/common';
import { Disease as DiseaseInfo } from 'types/disease';
import { isRecordLocationState } from 'types/record';

const imageAnchorRegExp = /image(?:s|-.*)/i;

const getDistributionNote = (disease: DiseaseInfo): string | string[] => {
  const countryList = disease.countries.list.filter(
    c => c.code !== 'worldwide' && c.code !== 'bioterrorism'
  );

  const endemicCount = countryList.filter(country => country.isEndemic).length;
  const allCountryCount = countryList.length;

  if (countryList[0].isChloroquineResistant !== undefined) {
    const resistantCount = countryList.filter(x => x.isChloroquineResistant === true).length;
    const sensitiveCount = countryList.filter(x => x.isChloroquineSensitive === true).length;

    return [
      `Chloroquine-resistant falciparum malaria is endemic to ${resistantCount} countries`,
      `Chloroquine-sensitive malaria is endemic to ${sensitiveCount} countries`,
    ];
  } else {
    return endemicCount >= allCountryCount
      ? 'Endemic to all countries'
      : endemicCount > 0 && endemicCount < allCountryCount
      ? `Endemic or potentially endemic to ${endemicCount} of ${allCountryCount} countries`
      : 'Not endemic to any country';
  }
};

type Props = RouteComponentProps & {
  diseaseId: number;
};

export const Disease: FC<Props> = ({ diseaseId, location }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showTransmission, setShowTransmission] = useState(true);
  const [showImages, setShowImages] = useState(true);
  const [showFindings, setShowFindings] = useState(true);
  const [showEndemicCountries, setShowEndemicCountries] = useState(true);
  const [showNotEndemicCountries, setShowNotEndemicCountries] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const disease = useDiseaseDetails(diseaseId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(disease)) {
    return <Loader />;
  }

  const getDescription = (): string => {
    const distributionNote = getDistributionNote(disease);
    const distributionText =
      typeof distributionNote === 'string' ? distributionNote : distributionNote.join(' ');

    return `GIDEON - ${
      disease.details.disease
    } is ${distributionText.toLocaleLowerCase()}. Agent: ${getStringFromDynamicHtml(
      disease.details.agentText
    )}`;
  };

  const sections: RecordLink[] = [
    { label: 'Summary', 'aria-label': `${disease.details.disease} summary` },
    {
      label: 'Transmission',
      disabled: isDisabled([
        disease.details.associated_microbes,
        disease.details.vectorNotes,
        disease.details.reservoirNotes,
        disease.details.vehicleNotes,
      ]),
      'aria-label': `${disease.details.disease} transmission`,
    },
    { label: 'Images', 'aria-label': `${disease.details.disease} images` },
    { label: 'Clinical findings', 'aria-label': `${disease.details.disease} clinical findings` },
    {
      label: `Endemic or potentially endemic countries (${
        disease.countries.list.filter(d => d.isEndemic && d.id !== 'G100' && d.id !== 'G90')?.length
      })`,
      disabled: disease.countries.list.filter(d => d.isEndemic && d.id !== 'G100' && d.id !== 'G90')
        ?.length
        ? false
        : true,
      'aria-label': `${disease.details.disease} endemic`,
    },
    {
      label: `Non-endemic countries (${
        disease.countries.list.filter(d => !d.isEndemic && d.id !== 'G100' && d.id !== 'G90')
          ?.length
      })`,
      disabled: disease.countries.list.filter(
        d => !d.isEndemic && d.id !== 'G100' && d.id !== 'G90'
      )?.length
        ? false
        : true,
      'aria-label': `${disease.details.disease} non-endemic`,
    },
    {
      label: 'Downloads and references',
      'aria-label': `${disease.details.disease} downloads and references`,
    },
  ];

  return (
    <ReferenceProvider>
      <Helmet>
        <title>{disease.details.disease} - GIDEON</title>
        <meta name="description" content={getDescription()} />
      </Helmet>
      <ReferenceLabelContext.Provider value={disease.details.disease}>
        <MainLayout>
          <RecordLayout>
            <RecordTitle
              title={disease.details.disease}
              synonymName={synonymName}
              diseaseId={diseaseId}
              buttons={
                <SmartCompareButton
                  section="diseases"
                  id={disease.details.code}
                  size="big"
                  showTooltip
                />
              }
              showTimeStamp={getTimeStamp(
                Math.max(
                  disease.details.timestamp,
                  disease.countries.timestamp,
                  disease.images.timestamp
                )
              )}
              distributionNote={getDistributionNote(disease)}
            />
            <RecordLinks links={sections} />
            <DistributionSection code={disease.details.code} countries={disease.countries} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="disease-summary"
            >
              <DiseaseSummarySection
                isTransmissionSection={
                  !isDisabled([
                    disease.details.reservoirNotes,
                    disease.details.vectorNotes,
                    disease.details.vehicleNotes,
                  ])
                }
                details={disease.details}
                diseaseId={diseaseId}
              />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showTransmission}
              onCollapse={() => setShowTransmission(!showTransmission)}
              accent="second"
              id="Transmission"
            >
              <TransmissionSection
                transmission={disease.details.transmission}
                reservoirs={disease.details.reservoirNotes}
                vectors={disease.details.vectorNotes}
                vehicles={disease.details.vehicleNotes}
                associated_microbes={disease.details.associated_microbes}
              />
            </RecordSection>
            <RecordSection
              link={sections[2]}
              show={showImages}
              onCollapse={() => setShowImages(!showImages)}
              accent="third"
              anchorRegex={imageAnchorRegExp}
              id="disease-images"
            >
              <ImagesSection images={disease.images.images} />
            </RecordSection>
            <RecordSection
              link={sections[3]}
              show={showFindings}
              onCollapse={() => setShowFindings(!showFindings)}
              accent="fourth"
              id="disease-clinical-findings"
            >
              <ClinicalFindingsSection details={disease.details} />
            </RecordSection>
            <RecordSection
              link={sections[4]}
              show={showEndemicCountries}
              onCollapse={() => setShowEndemicCountries(!showEndemicCountries)}
              accent="second"
              id="country-Endemic-diseases"
            >
              <P>
                Autochtonous cases are reported or have been reported in recent years, or there is a
                likelihood of human disease by virtue of an ongoing presence of the infecting agent
                in local reservoirs/vectors.
              </P>
              <DiseaseCountrySection
                countries={disease.countries.list.filter(country => country.isEndemic)}
                diseaseId={diseaseId.toString()}
              />
            </RecordSection>
            <RecordSection
              link={sections[5]}
              show={showNotEndemicCountries}
              onCollapse={() => setShowNotEndemicCountries(!showNotEndemicCountries)}
              accent="second"
              id="country-Endemic-diseases"
            >
              <DiseaseCountrySection
                countries={disease.countries.list.filter(country => !country.isEndemic)}
                diseaseId={diseaseId.toString()}
              />
            </RecordSection>
            <RecordSection
              link={sections[sections.length - 1]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fifth"
              id="disease-download"
            >
              <DownloadsSection
                id={diseaseId}
                category="diseases"
                labelTitle={disease.details.disease}
              />
            </RecordSection>
          </RecordLayout>
        </MainLayout>
      </ReferenceLabelContext.Provider>
    </ReferenceProvider>
  );
};

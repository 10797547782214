export type Dictionary<T> = { [key: string]: T };

export type PaginationData = {
  total: number;
  offset: number;
  limit: number;
};

export type Timestamp = number | undefined;

export const Loading = 'Loading';
export type LoadingType = typeof Loading;
export const isLoading = (state: unknown): state is LoadingType => state === Loading;
export const isNotLoading = <T>(value: T): value is Exclude<T, LoadingType> => !isLoading(value);

export const isDisabled = (data: any): boolean => {
  let flag = true;
  for (let i = 0; i < data.length; i++) {
    flag = true; // check till the last value
    if (data[i] && data[i].length) {
      flag = false;
      break;
    }
  }
  return flag;
};

import { Dictionary } from 'types/common';
import { alphabet } from 'utils/alphabet';

type Entry = { 
   id: number | string;
   name: string;
   has_country_note?:boolean;
   hasCountryNote?:boolean
  };

type AlphabeticallyGroupedEntries = Dictionary<Entry[]>;

export const groupDataAlphabetically = (
  data: Entry[],
  removeEmptySections?: boolean,
  skipWord?: string
): AlphabeticallyGroupedEntries => {
  const sections: AlphabeticallyGroupedEntries = alphabet.reduce(
    (acc, val) => ({ ...acc, [val]: [] }),
    {}
  );

  data.forEach(x => {
    if (!x.name) {
      return;
    }

    let k: string;
    if (skipWord && x.name.includes(skipWord)) {
      const splitted = x.name.split(' ');
      k = splitted[1][0].toUpperCase();
    } else {
      k = x.name[0].toUpperCase();
    }

    if (sections[k]) sections[k].push(x);
  });

  if (removeEmptySections) {
    Object.keys(sections).forEach(key => {
      if (sections[key].length === 0) {
        delete sections[key];
      }
    });
  }

  return sections;
};

export type AlphabeticallyGroupedStrings = Dictionary<string[]>;

export const groupDataAlphabeticallyFromStrings = (
  data: string[],
  removeEmptySections?: boolean
): AlphabeticallyGroupedStrings => {
  const sections: AlphabeticallyGroupedStrings = alphabet.reduce(
    (acc, val) => ({ ...acc, [val]: [] }),
    {}
  );
  data.forEach(x => {
    const k: string = x[0].toUpperCase();
    if (sections[k]) sections[k].push(x);
  });

  if (removeEmptySections) {
    Object.keys(sections).forEach(key => {
      if (sections[key].length === 0) {
        delete sections[key];
      }
    });
  }
  return sections;
};
